<h2 class="text-3xl md:text-4xl font-semibold mt-9">Skills</h2>

<div class="flex items-center gap-3 mt-3 mb-3">
  <h3 class="font-semibold -ml-2 pl-2">Design</h3>
  <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
</div>
<ul class="list-disc ml-4">
  <li>Over 20 years of experience in UI/UX and graphic design</li>
  <li>Expert user of Adobe Illustrator, InDesign, Photoshop, XD, Figma, and Sketch</li>
  <li>Comfortable designing live in front of groups of any size</li>
  <li>Strong background in motion design and micro-interactions</li>
  <li>Experienced in responsive design and working with design systems</li>
  <li>Advocate for rapid iteration and design-in-code methodologies</li>
</ul>

<div class="flex items-center gap-3 mt-4 mb-3">
  <h3 class="font-semibold -ml-3 pl-3">Development</h3>
  <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
</div>
<ul class="list-disc ml-4">
  <li>Over 20 years of experience in PHP, Python, HTML, CSS, and JavaScript</li>
  <li>Highly skilled in Django, Node.js, React, Svelte, and Tailwind CSS</li>
  <li>Expert in creating pixel-perfect, responsive web interfaces</li>
  <li>Solid experience with relational and NoSQL databases</li>
  <li>Extensive experience building RESTful and GraphQL APIs</li>
  <li>Experienced in PWA and SPA development stacks</li>
  <li>Strong foundation in Git version control, including advanced features</li>
  <li>Advocate of the reactive programming paradigm</li>
  <li>Enthusiastic mentor and collaborative team member</li>
</ul>

<div class="flex items-center gap-3 mt-4 mb-3">
  <h3 class="font-semibold -ml-3 pl-3">Systems</h3>
  <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
</div>
<ul class="list-disc ml-4">
  <li>Over 20 years of experience in UNIX system administration</li>
  <li>Deep knowledge of macOS, GNU/Linux, FreeBSD, and OpenBSD</li>
  <li>Experienced with monitoring tools like Grafana, Splunk, Nagios, New Relic, and CloudWatch</li>
  <li>Competent in Docker, Kubernetes, Terraform, and LXC</li>
  <li>Skilled in hardware forensics and data recovery</li>
  <li>Knowledgeable in serverless and infrastructure-less architectures</li>
  <li>Experienced in DNS management using BIND, djbdns, and Squid</li>
  <li>Administered SendGrid, Sendmail, qmail, and Postfix</li>
  <li>Advanced shell scripting skills (bash, csh, zsh)</li>
</ul>